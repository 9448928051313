import React, { useState } from "react";
import emailjs from "emailjs-com";

const GetInTouch = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState(""); // To handle the response status (success/error)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, email, message } = formData;

    // Check if all fields are filled
    if (!name || !email || !message) {
      setStatus("All fields are required.");
      return;
    }

    const templateParams = {
      name : name,
      email: email,
      message: message,
    };

    emailjs
      .send(
        "service_c6dlw1v", 
        "template_wotw8j9",
        templateParams,
        "xl3P3SOjfCy-_DPZS" 
      )
      .then(
        (response) => {
          setStatus("Message sent successfully!");
          setFormData({ name: "", email: "", message: "" }); // Clear form on success
        },
        (error) => {
          setStatus("Failed to send message, please try again.");
        }
      );
  };

  return (
    <div className="pt-10">
      <div className="grid grid-cols-1 md:grid-cols-2 border rounded-2xl overflow-hidden">
        <div className="h-full w-full flex-1 basis-[18rem]">
          <img src="/images/property (39).jpg" alt="" className="w-full h-full" />
        </div>
        <div className="flex-1 basis-[18rem] bg-secondary items-center py-28 !text-slate-200">
          <div className="max-w-[350px] w-full mx-auto border p-3 rounded-lg">
            <h1 className="text-lg font-semibold">Get in touch</h1>
            <p>
              For more inquiries or deals, just contact us using the form below.
              We will get back to you!
            </p>
            <div className="mt-4">
              <input
                type="text"
                name="name"
                className="w-full px-2 py-1 border rounded-md outline-none bg-secondary"
                placeholder="Your name.."
                value={formData.name}
                onChange={handleChange}
              />
              <input
                type="email"
                name="email"
                className="w-full px-2 py-1 mt-3 border rounded-md outline-none bg-secondary"
                placeholder="Your email.."
                value={formData.email}
                onChange={handleChange}
              />
              <textarea
                name="message"
                className="w-full p-2 mt-3 border rounded-md outline-none bg-secondary"
                rows={3}
                placeholder="Your message.."
                value={formData.message}
                onChange={handleChange}
              ></textarea>
              <button
                className="w-full mt-4 btn btn-primary"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>

            {status && <p className="mt-4 text-center">{status}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
